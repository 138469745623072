import React from "react";
import { graphql } from "gatsby";
import MDXRenderer from "gatsby-mdx/mdx-renderer";
import { Layout } from "../components/Layout";
import { Seo } from "../components/Seo";
import Post from "../components/Post";

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx;

    const imgs = this.props.data.mdx.frontmatter.images;

    return (
      <Layout>
        <Seo
          title={post.frontmatter.title}
          description={
            post.frontmatter.seoDescription || "Amazing article by Jakub Joras"
          }
        />
        <main>
          <Post
            title={post.frontmatter.title}
            date={post.frontmatter.date}
            coverImage={post.frontmatter.coverImage}
            tags={post.frontmatter.tags}
            previousPost={undefined}
            nextPost={undefined}
          >
            <MDXRenderer
              images={imgs}
              testowyProp={this.props.data.site.siteMetadata.title}
            >
              {post.code.body}
            </MDXRenderer>
          </Post>
        </main>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      code {
        body
      }
      frontmatter {
        tags
        title
        date(formatString: "MMMM YYYY")
        seoDescription
        coverImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
              presentationWidth
              presentationHeight
            }
          }
        }
        images {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
  }
`;
